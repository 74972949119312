import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import { Col } from 'styled-bootstrap-grid';

const cycleImages = keyframes`
  0% {
    visibility: visible;
    z-index: 100;
  }
	20% {
    visibility: visible;
    z-index: 100;
  }
	40% {
    visibility: hidden;
    z-index: 0;
  }
  60% {
    visibility: hidden;
    z-index: 0;
  }
  80% {
    visibility: hidden;
    z-index: 0;
  }
  100% {
    visibility: visible;
    z-index: 100;
   }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  margin-bottom: 50px;

  cursor: pointer;

  &:hover h4 {
    color: #fa3c44;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  & .images-container {
    position: relative;
    overflow: hidden;
    width: 100%;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 133%;
      /* background-color: rgba(69, 109, 232, 0.4); */
    }

    &:hover .gatsby-image-wrapper {
      animation-play-state: running;
    }

    & .gatsby-image-wrapper {
      height: auto;
      width: 100%;
      position: absolute !important;
      top: 0;
      left: 0;

      & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      animation: ${cycleImages} 2.4s linear infinite 0s forwards;
      animation-play-state: paused;

      & > div {
        padding-bottom: 133% !important;
      }
    }

    & .gatsby-image-wrapper:nth-child(1) {
      animation-delay: 0s;
      z-index: 5;
    }
    & .gatsby-image-wrapper:nth-child(2) {
      animation-delay: 0.6s;
      z-index: 4;
    }
    & .gatsby-image-wrapper:nth-child(3) {
      animation-delay: 1.2s;
      z-index: 3;
    }
    & .gatsby-image-wrapper:nth-child(4) {
      animation-delay: 1.8s;
      z-index: 2;
    }
  }

  & .title {
    text-align: center;
    margin-top: 20px;

    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }
`;

export const MultiContentSection = () => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          images {
            thumbnail_image {
              fluid(maxWidth: 400) {
                srcSetWebp
                srcWebp
              }
            }
          }
          announcement_text
          announcement_link {
            url
          }
          visible
          type
        }
      }
    }
  `);

  if (data.prismicHomepage.data.visible) {
    return (
      <Col col={6} md={4}>
        <Container
        // onClick={() => {
        // if (data.prismicHomepage.data.type === 'Together') {
        //   if (typeof window !== `undefined`) {
        //     let dims = document
        //       .querySelector('#together-container')
        //       .getBoundingClientRect();
        //     window.scrollTo({ top: dims.top - 35, behavior: 'smooth' });
        //   }
        // } else {
        // navigate(data.prismicHomepage.data.announcement_link.url);
        // }
        // }}
        >
          <div className="images-container">
            <a
              href={data.prismicHomepage.data.announcement_link.url}
              target="_blank"
              rel="noreferrer"
            >
              {data.prismicHomepage.data.images.map((image, index) => {
                return (
                  <div
                    className="gatsby-image-wrapper"
                    key={`single_artist_${index}`}
                  >
                    <img
                      srcSet={image.thumbnail_image.fluid.srcSetWebp}
                      src={image.thumbnail_image.fluid.srcWebp}
                      alt={image.thumbnail_image.alt}
                      key={index}
                    />
                  </div>
                );
              })}
            </a>
          </div>
          <div className="title">
            {data.prismicHomepage.data.announcement_text}
          </div>
        </Container>
      </Col>
    );
  } else {
    return null;
  }
};
