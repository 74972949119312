import React from 'react';
import styled, { keyframes } from 'styled-components';

const ArtistContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  margin-bottom: 50px;

  &:hover h4 {
    color: #fa3c44;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

const cycleImages = keyframes`
  0% { visibility: visible; z-index: 100; }
	33.333% { visibility: visible; z-index: 100; }
	66.666% { visibility: hidden; z-index: 0; }
  100% { visibility: hidden; z-index: 0; }
`;

const ArtistImages = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 133%;
    background-color: rgba(69, 109, 232, 0.4);
  }

  &:hover .gatsby-image-wrapper {
    animation-play-state: running;
  }

  & .gatsby-image-wrapper {
    height: auto;
    width: 100%;
    position: absolute !important;
    top: 0;
    left: 0;

    animation: ${cycleImages} 1.8s linear infinite 0s forwards;
    animation-play-state: paused;

    & > div {
      padding-bottom: 133% !important;
    }
  }

  & .gatsby-image-wrapper:nth-child(1) {
    z-index: 3;
  }
  & .gatsby-image-wrapper:nth-child(2) {
    animation-delay: 0.6s;
    z-index: 2;
  }
  & .gatsby-image-wrapper:nth-child(3) {
    animation-delay: 1.2s;
    z-index: 1;
  }
`;

const ArtistName = styled.h4`
  text-align: center;
  margin-top: 20px;

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`;

const SingleArtist = ({ images, name }) => (
  <ArtistContainer>
    <ArtistImages>
      {images.map((image, index) => {
        return (
          <div className="gatsby-image-wrapper" key={`single_artist_${index}`}>
            <img
              srcSet={image.image.fluid.srcSetWebp}
              src={image.image.fluid.srcWebp}
              alt={image.image.alt}
              key={index}
            />
          </div>
        );
      })}
    </ArtistImages>
    <ArtistName>{name}</ArtistName>
  </ArtistContainer>
);

export default SingleArtist;
