import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const IframeContainer = styled.div`
  position: relative;
  padding-bottom: ${(props) => props.aspectRatio + `%`};
  margin-bottom: 30px;
  height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ShowreelContainer = styled.div`
  /* padding: 0 0 100px 0; */

  & .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 100;
    color: #fa3c44;

    & h2 {
      font-size: 150px;

      @media (max-width: 1100px) {
        font-size: 120px;
      }

      @media (max-width: 850px) {
        font-size: 10vw;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 50px 0;
  }

  @media (max-width: 500px) {
    padding: 10px 0 0 0;
  }
`;

const Poster = styled.div`
  & img {
    z-index: 40;

    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

async function getVimeoThumbnail(video, width, height) {
  let response = await fetch(
    `https://vimeo.com/api/oembed.json?url=${video}&width=${width}&height=${height}&quality=1080`
  );

  let data = await response.json();
  return data;
}

export const AnimationShowreel = () => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          animation_showreel {
            url
            html
            embed_url
            width
            height
            thumbnail_url
          }
        }
      }
    }
  `);

  const [isPosterVisible, setPosterVisible] = useState(true);
  const [thumbnail, setThumbnail] = useState(null);

  let vimeoThumbnail = getVimeoThumbnail(
    data.prismicHomepage.data.animation_showreel.embed_url,
    1920,
    1080
  );

  useEffect(() => {
    vimeoThumbnail.then((result) => {
      setThumbnail(result.thumbnail_url);
    });
  }, [vimeoThumbnail]);

  return (
    <ShowreelContainer>
      <IframeContainer
        aspectRatio={
          (data.prismicHomepage.data.animation_showreel.height /
            data.prismicHomepage.data.animation_showreel.width) *
          100
        }
      >
        <div className="title">
          <h2>Animation</h2>
        </div>
        {isPosterVisible && thumbnail !== null && (
          <Poster>
            <img src={thumbnail} alt="Thumbnail" />
          </Poster>
        )}
        <ReactPlayer
          url={data.prismicHomepage.data.animation_showreel.embed_url}
          width="100%"
          height="100%"
          playing={true}
          controls
          loop={true}
          playsinline={true}
          autoPlay={true}
          volume={0}
          muted={true}
          onStart={() => {
            setPosterVisible(false);
          }}
          config={{
            vimeo: {
              playerOptions: {
                background: 1,
                loop: 1,
                autoPlay: 1,
                muted: 1,
              },
            },
          }}
        />
      </IframeContainer>
    </ShowreelContainer>
  );
};
