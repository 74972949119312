import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import { useInView } from 'react-intersection-observer';

import Subtitle from '../Text/subtitle';
import Filters from '../Artists/filters';
import AllArtists from '../Homepage/allArtists';
import { AllAnimationArtists } from '../Homepage/all-animation-artists';
import { AnimationShowreel } from './animation-showreel';
import { AgencyShowreel } from './agency-showreel';
import { MultiContentSection } from './multi-content-section';
import { SomethingForEveryone } from './something-for-everyone';

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  padding: 0 35px;

  & .projects-container,
  & .together-container {
    width: 100%;

    position: relative;
    z-index: 1;
  }

  @media (max-width: 767px) {
    padding: 0 25px;
  }

  @media (max-width: 576px) {
    padding: 0 20px;
  }
`;

const ArtistSubNav = styled.div`
  position: sticky;
  top: 79px;

  width: 100%;
  padding: 0 15px;
  z-index: 100;
  background-color: #f6f8fe;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;

  font-size: 18px;

  @media (max-width: 767px) {
    padding: 0;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    top: 72px;
  }
`;

const InnerIndex = ({ data, location, activeFilter }) => {
  return (
    <>
      <Page>
        {activeFilter === 'Illustration' ? (
          <Col col={12}>
            <AgencyShowreel text={data.prismicHomepage.data.text.html} />
          </Col>
        ) : (
          <Col col={12}>
            <AnimationShowreel />
          </Col>
        )}

        <ArtistSubNav id="filter-bar">
          <Filters activeFilter={activeFilter} location={location.pathname} />
        </ArtistSubNav>

        <div className="projects-container">
          <Col col={12}>
            <Row>
              {activeFilter !== 'Animation' && <MultiContentSection />}

              {activeFilter === 'Illustration' ? (
                <AllArtists
                  projects={data.allPrismicArtist.edges}
                  filter={activeFilter}
                />
              ) : (
                <AllAnimationArtists
                  projects={data.allPrismicArtist.edges}
                  filter={activeFilter}
                />
              )}
            </Row>
          </Col>
        </div>

        <SomethingForEveryone />
      </Page>

      {/* <div className="together-container" ref={ref}>
        <Together filter={activeFilter} />
      </div> */}
    </>
  );
};

export default InnerIndex;

// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { Row, Col } from 'styled-bootstrap-grid';
// import { useInView } from 'react-intersection-observer';

// import Subtitle from '../Text/subtitle';
// import Filters from '../Artists/filters';
// import AllArtists from '../Homepage/allArtists';
// import { Together } from './together';
// import { AnimationShowreel } from './animation-showreel';
// import { MultiContentSection } from './multi-content-section';

// const Page = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: center;

//   padding: 0 35px;

//   & .projects-container,
//   & .together-container {
//     position: relative;
//     z-index: 1;
//   }

//   @media (max-width: 767px) {
//     padding: 0 25px;
//   }

//   @media (max-width: 576px) {
//     padding: 0 20px;
//   }
// `;

// const ArtistSubNav = styled.div`
//   position: sticky;
//   top: 79px;

//   width: 100%;
//   padding: 0 15px;
//   z-index: 100;
//   background-color: #f6f8fe;

//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   align-items: baseline;

//   font-size: 18px;

//   @media (max-width: 767px) {
//     padding: 0;
//   }

//   @media (max-width: 500px) {
//     font-size: 16px;
//     top: 72px;
//   }
// `;

// const InnerIndex = ({ data, location }) => {
//   const [activeFilter, setActiveFilter] = useState('Illustration');
//   const [allFilters, setAllFilters] = useState(
//     Array.from(
//       new Set(data.allPrismicArtist.edges.map((tag) => tag.node.tags).flat())
//     )
//   );
//   const [projects, setProjects] = useState(data.allPrismicArtist.edges);
//   const [togetherThumbnails, setTogetherThumbnails] = useState(
//     data.prismicHomepage.data.thumbnail_images
//   );

//   const [isTogetherLogoVisible, setIsTogetherLogoVisible] = useState(false);

//   const { ref, inView, entry } = useInView({
//     // root: document.body,
//     threshold: [
//       0, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7,
//       0.75, 0.8, 0.85, 0.9, 0.95, 1,
//     ],
//     // rootMargin: '79px 0px 0px 0px',
//     // threshold: 0,
//     // trackVisibility: true,
//     // delay: 100,
//   });

//   useEffect(() => {
//     let header = document.querySelector('header');
//     header.style.transition = 'none';
//     header.style.transitionDelay = '';

//     if (entry !== undefined) {
//       if (entry.boundingClientRect.top <= 105 && inView) {
//         header.style.backgroundColor = '#b5c5f6';
//         header.style.paddingBottom = '7px';

//         // console.log('is intersesting');
//       } else {
//         header.style.backgroundColor = '#f6f8fe';
//         header.style.paddingBottom = '26px';

//         // console.log('is not intersesting');
//       }
//     }
//   }, [entry, inView]);

//   const changeFilter = (filter) => {
//     setActiveFilter(filter);

//     if (filter === 'Illustration') {
//       setProjects(data.allPrismicArtist.edges);
//     } else {
//       let filteredProjects = data.allPrismicArtist.edges.filter((project) =>
//         project.node.tags.includes(activeFilter)
//       );

//       setProjects(filteredProjects);
//     }
//   };

//   return (
//     <>
//       <Page>
//         {activeFilter === 'Illustration' ? (
//           <Col col={12} md={10} lg={9} xl={8}>
//             <Subtitle
//               text={data.prismicHomepage.data.text}
//               padding={'100px 0'}
//             />
//           </Col>
//         ) : (
//           <Col col={12}>
//             <AnimationShowreel />
//           </Col>
//         )}

//         <ArtistSubNav id="filter-bar">
//           <Filters
//             onClick={changeFilter}
//             activeFilter={activeFilter}
//             allFilters={allFilters}
//             location={location.pathname}
//           />
//         </ArtistSubNav>

//         <div className="projects-container">
//           <Col col={12}>
//             <Row>
//               {activeFilter !== 'Animation' && <MultiContentSection />}

//               <AllArtists projects={projects} filter={activeFilter} />
//             </Row>
//           </Col>
//         </div>
//       </Page>

//       <div className="together-container" ref={ref}>
//         <Together filter={activeFilter} />
//       </div>
//     </>
//   );
// };

// export default InnerIndex;
