import React from 'react';
import styled from 'styled-components';

const ArtistContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  margin-bottom: 50px;

  &:hover h4 {
    color: #fa3c44;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

const ArtistImages = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 62.4%;
    background-color: rgba(69, 109, 232, 0.4);
  }

  & .gatsby-image-wrapper {
    height: auto;
    width: 100%;
    position: absolute !important;
    top: 0;
    left: 0;

    & > div {
      padding-bottom: 133% !important;
    }
  }
`;

const ArtistName = styled.h4`
  text-align: center;
  margin-top: 20px;

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`;

export const SingleAnimationArtist = ({ image, name }) => (
  <ArtistContainer>
    <ArtistImages>
      <div className="gatsby-image-wrapper">
        {image.fluid !== null && (
          <img
            srcSet={image.fluid.srcSetWebp}
            src={image.fluid.srcWebp}
            alt={image.alt}
          />
        )}
      </div>
    </ArtistImages>
    <ArtistName>{name}</ArtistName>
  </ArtistContainer>
);
