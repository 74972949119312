import React from 'react';
import { Col } from 'styled-bootstrap-grid';
import { Link } from 'gatsby';

import { SingleAnimationArtist } from './single-animation-artist';

export const AllAnimationArtists = ({ projects, filter }) =>
  projects
    .filter(
      (project) => project.node.data.homepage_animation_image.fluid !== null
    )
    .map((project) => {
      return (
        <Col col={6} key={project.node.prismicId}>
          <Link
            to={'/artists/' + project.node.uid + `/animation/`}
            state={{ activeFilter: filter }}
          >
            <SingleAnimationArtist
              image={project.node.data.homepage_animation_image}
              name={project.node.data.name.text}
            />
          </Link>
        </Col>
      );
    });
