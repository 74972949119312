import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const FiltersContainer = styled.div`
  margin-bottom: 30px;
  display: block;

  @media (max-width: 767px) {
    order: 3;
    flex: 0 0 100%;
    display: ${(props) => (props.isMobileFilterMenuOpen ? 'block' : 'none')};
  }
`;

const PageLink = styled.div`
  display: inline-block;
  margin-right: 22px;

  & a {
    text-transform: capitalize;
    border-bottom: 2px solid transparent;

    &.active {
      border-bottom: 2px solid #fa3c44;
    }

    &:hover {
      color: #fa3c44;
      border-bottom: 2px solid #fa3c44;
    }
  }
`;

const MobileFilterToggle = styled.button`
  order: 1;

  display: none;
  margin-right: 22px;
  margin-bottom: 30px;

  text-transform: capitalize;

  color: ${(props) =>
    props.isMobileFilterOpen ? '#fa3c44' : 'rgba(1,59,210,1)'};

  @media (max-width: 767px) {
    display: block;
  }
`;

const Filters = ({ activeFilter, onClick, allFilters, location }) => {
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  return (
    <React.Fragment>
      <MobileFilterToggle
        isMobileFilterOpen={isMobileFilterOpen}
        onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)}
      >
        + Filter
      </MobileFilterToggle>

      <FiltersContainer isMobileFilterMenuOpen={isMobileFilterOpen}>
        <PageLink>
          <Link to={`/`} activeClassName="active">
            Illustration
          </Link>
        </PageLink>

        <PageLink>
          <Link to={`/animation/`} activeClassName="active">
            Animation
          </Link>
        </PageLink>
      </FiltersContainer>
    </React.Fragment>
  );
};

export default Filters;
