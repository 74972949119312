import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { keyframes } from 'styled-components';

const cycleImages = keyframes`
  0% {
    visibility: visible;
    z-index: 100;
  }
	20% {
    visibility: visible;
    z-index: 100;
  }
	40% {
    visibility: hidden;
    z-index: 0;
  }
  60% {
    visibility: hidden;
    z-index: 0;
  }
  80% {
    visibility: hidden;
    z-index: 0;
  }
  100% {
    visibility: visible;
    z-index: 100;
   }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  margin-bottom: 50px;

  cursor: pointer;

  &:hover h4 {
    color: #fa3c44;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  & .image-container {
    position: relative;
    overflow: hidden;
    width: 100%;

    max-width: 75vw;
    margin: 0 auto;

    @media (max-width: 500px) {
      max-width: 100%;
    }

    & .mobile-image {
      display: none;

      @media (max-width: 767px) {
        display: block;
      }
    }

    & .desktop-image {
      display: block;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
`;

export const SomethingForEveryone = () => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          desktop_image {
            fluid {
              srcSet
              src
            }
          }

          mobile_image_top {
            fluid {
              srcSet
              src
            }
          }

          mobile_image_bottom {
            fluid {
              srcSet
              src
            }
          }

          link {
            url
          }
          display_section
        }
      }
    }
  `);

  if (data.prismicHomepage.data.display_section) {
    return (
      <Container>
        <div className="image-container">
          <a
            href={data.prismicHomepage.data.link.url}
            target="_blank"
            rel="noreferrer"
          >
            <img
              srcSet={data.prismicHomepage.data.desktop_image.fluid.srcSet}
              src={data.prismicHomepage.data.desktop_image.fluid.src}
              alt=""
              className="desktop-image"
              loading="lazy"
            />

            <img
              srcSet={data.prismicHomepage.data.mobile_image_top.fluid.srcSet}
              src={data.prismicHomepage.data.mobile_image_top.fluid.src}
              alt=""
              className="mobile-image"
              loading="lazy"
            />

            <img
              srcSet={
                data.prismicHomepage.data.mobile_image_bottom.fluid.srcSet
              }
              src={data.prismicHomepage.data.mobile_image_bottom.fluid.src}
              alt=""
              className="mobile-image"
              loading="lazy"
            />
          </a>
        </div>
      </Container>
    );
  } else {
    return null;
  }
};
