import React from 'react';
import { Col } from 'styled-bootstrap-grid';
import { Link } from 'gatsby';

import SingleArtist from './singleArtist';

const AllArtists = ({ projects, filter }) => {
  if (filter === `Animation`) {
    return projects.map((project) => {
      return (
        <Col col={6} md={4} key={project.node.prismicId}>
          <Link
            to={'/artists/' + project.node.uid + `/animation/`}
            state={{ activeFilter: filter }}
          >
            <SingleArtist
              images={project.node.data.homepage_images}
              name={project.node.data.name.text}
            />
          </Link>
        </Col>
      );
    });
  } else {
    return projects.map((project) => {
      return (
        <Col col={6} md={4} key={project.node.prismicId}>
          <Link
            to={'/artists/' + project.node.uid}
            state={{ activeFilter: filter }}
          >
            <SingleArtist
              images={project.node.data.homepage_images}
              name={project.node.data.name.text}
            />
          </Link>
        </Col>
      );
    });
  }
};

export default AllArtists;
