import React from 'react';
import styled from 'styled-components';

const SubtitleContainer = styled.div`
  padding: ${props => props.padding};

  & h3 {
    font-size: 36px;
    line-height: 45px;

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  @media (max-width: 767px) {
    padding: 50px 0;
  }
`;

const Subtitle = ({ text, padding }) => {
  return (
    <SubtitleContainer
      padding={padding}
      dangerouslySetInnerHTML={{
        __html: text.html,
      }}
    />
  );
};

export default Subtitle;
